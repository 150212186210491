<template>
  <div>
    <div class="flex justify-space-between">
      <div class="box self-flex-end"><h2 class="mv-0">INVOICES REPORT</h2></div>
      <div class="box"><el-button @click="$onCommandParams('settings')">BACK</el-button></div>
    </div>
    <el-divider class="mv-10 o-020"></el-divider>
    <div class="flex gaps mv-10 justify-space-between">
        <div class="box left center">
          <el-input placeholder="Search"
                    size="small"
                    ref="search"
                    v-model="search"
                    style="width: 200px;"
                    @input="handleSearchInput"
                    class="box center"></el-input>
        </div>
        <div class="box grow left">
          <el-date-picker
            :append-to-body="false"
            size="small"
            v-model="dateSearch"
            type="date"
            placeholder="Pick a day"
            @change="onChangeDate"
            :picker-options="pickerOptions">
          </el-date-picker>
        </div>
        <div class="box rigth">
          <el-checkbox v-model="isHideTech">Hide tech invoices</el-checkbox>
        </div>
        <div class="box rigth">
          <el-button size="small" @click="dialogExportVisible = true">export</el-button>
        </div>
    </div>
    <div :style="{width: tableWidth + 'px'}">
      <el-table
        :summary-method="getSummaries"
        :row-class-name="tableRowClassName"
        :max-height="tableHeight"
        show-summary
        border
        :data="filtered">
        <el-table-column
          prop="uid"
          width="70"
          label="Id">
        </el-table-column>
        <el-table-column
          width="165"
          label="Date">
          <template #default="scope">
            {{formatDateTime(scope.row.date)}}
          </template>
        </el-table-column>
        <el-table-column
          column-key="total"
          prop="total"
          class-name="bg-primary-light"
          align="right"
          label="Total">
          <template #default="scope">
            {{scope.row.total}}
          </template>
        </el-table-column>
        <el-table-column
          v-for="c in companies"
          :key="c.uid"
          align="right"
          :column-key="c.name"
          :label="c.name">
            <template #default="scope">
              {{scope.row.hasOwnProperty(c.name) ? scope.row[c.name] : ''}}
            </template>
        </el-table-column>
        <el-table-column
          prop="cash"
          align="right"
          column-key="cash"
          label="Cash">
        </el-table-column>
        <el-table-column
          prop="card"
          align="right"
          column-key="card"
          label="Card">
        </el-table-column>
        <el-table-column
          prop="posName"
          width="180"
          label="Waivers">
            <template #default="scope">
              <div v-for="(w, index) in scope.row.waivers" :key="index" class="info-text fs-12">
                {{w.slice(0,4) === 'mem:' ? w.slice(4) : w }}
              </div>
            </template>
        </el-table-column>
        <el-table-column
          prop="sales"
          width="160"
          label="Sales">
            <template #default="scope">
              <div v-for="(s, index) in scope.row.sales" :key="index" class="fs-12 flex gaps">
                <span class="box grow justify-flex-start">{{s[0]}}</span><span class="box justify-flex-end">{{s[1]}}</span>
              </div>
            </template>
        </el-table-column>
        <el-table-column
          prop="pos"
          label="POS">
        </el-table-column>
        <el-table-column
          prop="author"
          label="Operator">
        </el-table-column>
        <el-table-column
          prop="isRefund"
          width="65"
          align="center"
          label="refund">
            <template #default="scope">
              <i class="mdi mdi-alert-outline accent-text fs-20" v-if="scope.row.isRefund"></i>
              <el-button v-else-if="scope.row.hasOwnProperty('memo')" type="text" size="small" @click="$onCommandParams('invoice', { id: scope.row.memo })">{{scope.row.memo}}</el-button>
            </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
              <div class="flex gaps">
                  <el-button @click.native.prevent="openRow(scope.row)" type="text" size="small">
                      Open
                  </el-button>
              </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <app-dialog-export
      @close="dialogExportVisible = false"
      :dialogExportVisible="dialogExportVisible"></app-dialog-export>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import AppDialogExport from './Export'

export default {
  name: 'InvoicesReport',

  components: {
    AppDialogExport
  },

  data () {
    return {
      dateSearch: '',
      isHideTech: true,
      search: '',
      dialogExportVisible: false,
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [{
          text: 'Today',
          onClick (picker) {
            picker.$emit('pick', new Date())
          }
        }, {
          text: 'Yesterday',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          }
        }, {
          text: '2 days ago',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 2)
            picker.$emit('pick', date)
          }
        }, {
          text: '3 days ago',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 3)
            picker.$emit('pick', date)
          }
        }, {
          text: '4 days ago',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 4)
            picker.$emit('pick', date)
          }
        }, {
          text: '5 days ago',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 5)
            picker.$emit('pick', date)
          }
        }, {
          text: '6 days ago',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 6)
            picker.$emit('pick', date)
          }
        }, {
          text: '7 days ago',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          }
        }]
      }
    }
  },

  computed: {
    ...mapState({
      innerHeight: state => state.nav.innerHeight,
      innerWidth: state => state.nav.innerWidth,
      companies: state => state.company.companies
    }),
    ...mapGetters(['invoices']),
    tableHeight () {
      return this.innerHeight - 181
    },
    tableWidth () {
      return this.innerWidth - 30
    },
    companiesNameArray () {
      return this.companies.map(c => c.name)
    },
    selectDay () {
      return this.formatDate(this.dateSearch)
    },
    start () {
      // let day = new Date(this.selectDay)
      return new Date(new Date(this.selectDay).setHours(6, 0, 0, 0))
    },
    end () {
      // let day = new Date(this.selectDay)
      return new Date(new Date(this.selectDay).setHours(30, 0, 0, 0))
    },
    filteredSearch () {
      return this.invoices
        .filter(i => !this.isHideTech || i.total > 0)
        .filter(item => {
          return this.search.length < 2 ||
         (item.hasOwnProperty('waivers') && item.waivers.some(w => w.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)) ||
         (item.hasOwnProperty('sales') && item.sales.some(s => s[0].toLowerCase().indexOf(this.search.toLowerCase()) !== -1)) ||
         (item.hasOwnProperty('pos') && item.pos !== undefined && item.pos.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) ||
         (item.hasOwnProperty('author') && item.author !== undefined && item.author.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
        })
    },
    filtered () {
      return this.filteredSearch.filter(i => this.start < new Date(i.date) && new Date(i.date) < this.end)
    }
  },

  methods: {
    onChangeDate (v) {
      this.$store.dispatch('getInvoicesByDate', this.formatDate(this.dateSearch))
    },
    openRow (row) {
      this.$onCommandParams('invoice', { id: row.uid })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (row.isRefund) {
        return 'locked-panel'
      } else if (row.hasOwnProperty('memo') && row.memo !== undefined) {
        return 'locked-warning-panel'
      } else if (row.total !== Number(row.cash) + Number(row.card)) {
        return 'warning-row'
      } else {
        return ''
      }
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Total'
          return
        }
        if ([...this.companiesNameArray, 'total', 'cash', 'card'].includes(column.columnKey)) {
          const values = data.map(item => Number(item[column.columnKey]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
          } else {
            sums[index] = ''
          }
        } else {
          sums[index] = ''
        }
      })
      sums[1] = 'from: ' + this.formatDate(this.start) + ' to: ' + this.formatDate(this.end)
      return sums
    },
    formatDate (date) {
      let d = new Date(date)
      let options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      return date !== undefined ? d.toLocaleString('fr-CA', options) : ''
    },
    formatDateTime (date) {
      if (date !== undefined) {
        let d = new Date(date)
        let minutes = d.getMinutes() > 9 ? d.getMinutes() : '0' + d.getMinutes()
        let hours = d.getHours()
        return this.formatDate(date) + '   ' + hours + ':' + minutes
      } else {
        return ''
      }
    },
    handleSearchInput () {}
  },
  mounted () {
    this.dateSearch = Date.now()
  }
}
</script>

<style lang="scss">
</style>
